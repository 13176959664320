.ContentInput {
    width: 100%;
    background-color: rgb(255, 255, 255);
    margin: 0;
    padding: 1vh 2vw;
    box-sizing: border-box;
    resize: none;
}

.ErrorMessage   { 
    height: 21px; 
    margin: 1vh 0;
    text-align: center;
}

.Btn{
    width:100%;
    color:rgb(15, 14, 14) !important;
}