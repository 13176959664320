.Auth {       
    text-align: center;
    background-color: rgba(0, 0, 17);
    padding: 2vh 5vw 4vh;
    margin: 0;
    box-sizing: border-box;
}

.AuthNav {
    display: flex;    
}
.AuthNav button{
    cursor: pointer;
    
}

.AuthToggle {
    border: none;
    background-color: transparent;
    white-space: nowrap;
    color: white;
    border-bottom: 3px solid transparent;
}

.AuthSelected {
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-width: 3px;
    border-bottom-style: solid;
}

.Btn {
    color: rgb(255, 255, 255);   
    width: 100%;
    margin: .5vh 0;
    padding: 1vh 0;
    cursor: pointer;
}

.InputWrapper{
    display: flex;
    align-items: center;
}

.InputWrapper span {
    margin: 0 1vw 0 2vw;
    font-size: 1.15em;
}

.AuthInput {
    color: rgb(255, 255, 255) !important;   
    width: 100%;
    height: 34px;
    padding: 1vh 10px;
    margin: 1vh 0;
    background-color: #04000a !important;
    border: 1px solid rgb(255, 255, 255);
    box-sizing: border-box;
}

.AuthInput:-webkit-autofill {
    background-color: black !important;
}

.Message        { min-height: 21px; }
.ErrorMessage   { height: 21px; }


@media only screen and (min-width: 600px) {
    /* For tablets: */
    .Auth {
        margin: 2vh 15vw;
    }
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .Auth {
        margin: 5vh 25vw;
    }
}
