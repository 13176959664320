.Archives {
    background-color: rgba(0, 0, 17, 0.95);
    padding: 1vh 2vw 4vh;
    //overflow-wrap: break-word;
    word-break: break-all;
}

.Archives ul {
    margin: 0;
    padding-left: 15px;
}

.Archives i {
    cursor: pointer;
}

.ShowNone {
    display: none;
}

.Author {
    padding-left:20px;
    list-style-type: disc;
}