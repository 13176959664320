.Logo {
    height: 80%;
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo img {
    height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
    .Logo {
      animation: App-logo-spin infinite 55s linear;
    }
}
  
@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  