.ProjectsLayout{
    padding: 2vh 0;
}
.Projects {
    background-color: rgba(0, 0, 17);
    padding: 2vh 5vw 4vh;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

@media only screen and (min-width: 600px) {
    .ProjectsLayout{
        padding: 2vh 5vw;
    }
}
@media only screen and (min-width: 1025px) {
    .ProjectsLayout{
        padding: 2vh 15vw;
    }
}