.Post {
    border-bottom: 1px solid rgba(255, 255, 255, 0.125);
    padding: 1vh 0;
    overflow-wrap: break-word;
}

.Title { 
    font-size: 1.2em;
    font-weight: bold;
    margin: 1.2vh 0;
}


.CardDate p{
    font-size: .85rem;
    color: rgb(134, 134, 134);
    margin:0;
}

.CardAuthor {
    margin: 2vh 0 1VH;
    background-color: rgb(9, 6, 31);
    padding: 1vh 2vw;
    border-radius: 10px;
}
.CardAuthor p{
    font-size: .75rem;
    color: rgb(223, 223, 223);
    margin: 0;
}

.Btn {
    color: rgb(255, 255, 255);   
    font-size: 1em;
    cursor: pointer;
}

.EditPost{
    margin: .5vh 0;
    width: 100%;
}

.Modal {
    display: flex;
    flex-direction: column;
    //align-items: stretch;
    justify-content:space-between ;
    height: 20vh;
}

.Comments {
    font-size: .85em;
    margin: 1vh 0;
}