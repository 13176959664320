.Wrapper {
    height: 100%;
    color: white;
}

.Main {
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
}
