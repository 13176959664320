.ShowMonths {
    padding-left:20px;
}

.Year{
    display: flex;
    align-items: center;
}

.YearName {
    cursor: pointer;
}

.Icon {
    text-align: center;
    font-size: 1.15em;
    width: 11px;
}