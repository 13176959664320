.AboutLayout{
    padding: 2vh 0;
}
.About {
    //margin: 10vh 5vw 0;
    padding: 2vh 4vw;
    background-color: #000011;
}

.figure {
    width: 200px;
    margin:0 auto;
//    float: right;
}

.figure img {
    text-align: center;
    width: 100%;
    border-radius: 50%;
    //float: right;
}


.bio {
    //display: flex;

}

.column {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
}

@media only screen and (min-width: 600px) {
    /* For tablets: */
    .AboutLayout{
        padding: 2vh 5vw;
    }
	.About {
        //margin: 0 15vw;
        //padding: 11vh 4vw;
    }

    .Projects {
		flex-direction:row;
		flex-wrap: wrap;
	}

    .figure {
        width: 200px;
        float: right;
    }

    .column {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1025px) {
    /* For desktop: */
    .AboutLayout{
        padding: 2vh 15vw;
    }
    .About {
        //margin: 0 15vw;
        //padding: 11vh 4vw;
        //height: 100%;
    }
    
    .figure {
        width: 250px;
        float: right;
    }
} 