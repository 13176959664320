.AddPostWrapper {padding: 2vh 0vw;}

.AddPost{ 
    box-sizing: border-box;
    grid-area: content; 
    background-color: rgba(0, 0, 17);
    //padding: 0vh 5vw 4vh;
}

.Archives {
    margin: 2vh 0;
}

.ContentInput {
    width: 100%;
    background-color: rgb(255, 255, 255);
    margin: 0;
    padding: 1vh 2vw;
    box-sizing: border-box;
    resize: none;
}

.TitleInput{
    width: 100%;
    margin: 0;
    padding: 1vh 2vw;
    box-sizing: border-box;
}


.AddPost  h1{ 
   // font-size: 1.5em;
   // margin-block-start: 0.83em;
   // margin-block-end: 0.83em;
}

.label {
    margin: 1vh 0;
}

.ErrorMessage   { 
    height: 21px; 
    margin: 1vh 0;
    text-align: center;
}

.Btn{
    width:100%;
    color:rgb(15, 14, 14) !important;
}

@media only screen and (min-width: 600px) {
    .AddPostWrapper {padding: 2vh 2vw;}
    .AddPost{
        flex-direction: row;
    }
    .Content {
        width: 69%;
        margin-right:1%;
    }
    .Archives {
        margin: 0;
        width:29%;
        margin-left:1%;
    }
}

@media only screen and (min-width: 1025px) {
    .AddPostWrapper {padding: 2vh 15vw;}
}