.ShowTitles {

}

.Titles {
    padding-left:20px;
    cursor: pointer;
}

.Month{
    display: flex;
    align-items: center;
}

.MonthName {
    cursor: pointer;
}

.Icon {
    text-align: center;
    font-size: 1.15em;
    width: 11px;
}