.BackgroundWrapper {
	width: 100%;
	text-align: center;
	overflow: hidden;
}
.BackgroundWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -90;
  transform: translateX(-50%) translateY(-50%);
 	background-color: black;
  background-size: cover;
  transition: 1s opacity;
}
.BackgroundWrapper video{
  //display: none;
}
.BackgroundWrapper img {
  display: none;
}

@media only screen and (min-width: 600px) {

}
@media only screen and (min-width: 1025px) {
  .BackgroundWrapper video{
      display: inherit;
  }
  .BackgroundWrapper img {
    display: none;
  }
}