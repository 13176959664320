html  {height: 100%}
#root {height: 100%}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {text-decoration: none;}

.btn {
  appearance: auto;
  -webkit-writing-mode: horizontal-tb !important;
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 1vh 1vw;
  margin: 0em;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 1em;
  border-width: 1px;
  border-style: outset;
  //border-color: internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-rendering: auto;
}

.auth-btn           { background-color: #6623d3;}
.my-auth-btn        { color: #6623d3;}
.auth-btn:hover     { background-color: #5b1fbd;}

.btn-default        { background-color: #db9e2b;}
.my-btn-default     { color: #db9e2b;}
.btn-default:hover  { background-color: #c08b29;}

.btn-primary        { background-color: #357ebd;}
.my-btn-primary     { color: #357ebd;}
.btn-primary:hover  { background-color: #2f6ea5;}

.btn-info           { background-color: #5bc0de;}
.my-btn-info        { color: #5bc0de;}
.btn-info:hover     { background-color: #50a6c0;}

.btn-danger         { background-color: #d9534f;}
.my-btn-danger      { color: #d9534f;}
.btn-danger:hover   { background-color: #be4c48;}

.btn-delete         { background-color: #921c18;}
.my-btn-delete      { color: #921c18;}
.btn-delete:hover   { background-color: #d6322c;}

.btn-cancel         { background-color: #464343;}
.my-btn-cancel      { color: #464343;}
.btn-cancel:hover   { background-color: #6e6b6b;}

.btn-edit          { background-color: rgb(4, 107, 107);}
.my-btn-edit      { color: rgb(4, 107, 107)}
.btn-edit:hover   { background-color: rgb(5, 143, 143)}

.text-center        { text-align: center; }
.text-right         { text-align: right; }
.text-left          { text-align: left; }
.pointer            { cursor: pointer;}
.margin-top-0       { margin-top:  0;}

.page-wrapper {
  min-height: 94vh;
  margin-top: 51px;
  //overflow: hidden;
  flex: 1 0 auto;
}

.spread {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //flex-direction: row;
}

.material-icons {
  font-size: 1.5em;
}

@media only screen and (min-width: 600px) {
  .page-wrapper {
    min-height: 50vh;
  }
}
@media only screen and (min-width: 1025px) {
  .page-wrapper {
  }
}
